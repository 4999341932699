<script>
import AuthService from "@/api/services/auth.service.js";
import IntegrationPartnerService from "@/api/services/integrationPartner.service.js";
import appConfig from "@/app.config";
import Layout from "@/router/layouts/main";
import PageHeader from "@/shared/components/page-header";
import Multiselect from "vue-multiselect";
import { maxLength, required } from "vuelidate/lib/validators";
import IngressPrerequisiteModal from "../components/modals/ingress-prerequisite-modal.vue";
import NipoUserAccessModal from "../components/modals/nipo-user-access-modal.vue";
import dataImportService from "@/api/services/dataImport.service";

/**
 * Live Import
 */
export default {
  page: {
    title: "Create Live Datafile",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: {
    Layout,
    PageHeader,
    Multiselect,
    IngressPrerequisiteModal,
    NipoUserAccessModal
  },
  data() {
    return {
      loggedInUser: null,
      datafileName: null,
      allIntegrationPartners: null,
      selectedIntegrationPartner: null,
      totalQuestionAdded: 0,
      isStep1Completed: false,
      /*** Operational Variables *****/
      isPageLoading: true,
      displayRequiredErrorForIntegrationPartner: false,
      displayIngressPrerequisiteModal: false,
      displayNipoUserAccessModal: false,
      isAllowedForDataImport: false
    };
  },
  validations: {
    datafileName: { required, maxLength: maxLength(100) }
  },
  computed: {
    items() {
      const items = [
        {
          text: "Dashboard",
          href: "/"
        },
        {
          text: this.$t("liveImportStep1.pageTitle"),
          active: true
        }
      ];
      return items;
    }
  },
  async mounted() {
    // getLoggedInUser
    this.loggedInUser = await AuthService.getLoggedInUser();
    this.allIntegrationPartners =
      await IntegrationPartnerService.getAllIntegrationPartners();

    // get Items from localstorage and set to local variable
    this.liveDatafileMetadata = JSON.parse(
      localStorage.getItem("liveDatafileMetadata")
    );

    console.log("this.liveDatafileMetadata: ", this.liveDatafileMetadata)

    if (this.liveDatafileMetadata) {
      this.isStep1Completed = true;
      this.datafileName = this.liveDatafileMetadata.name;
      this.totalQuestionAdded = this.liveDatafileMetadata.numberOfQuestionAdded;

      this.allIntegrationPartners.forEach((integrationPartner) => {
        if (integrationPartner.id === this.liveDatafileMetadata.integration) {
          this.selectedIntegrationPartner = integrationPartner;
        }
      });
    }
    this.isAllowedForDataImport = await dataImportService.checkAllowedForDataImport(1, this.loggedInUser.company.id);
    this.isPageLoading = false;
  },
  methods: {
    async closeNipoModal() {
      (this.displayNipoUserAccessModal = false),
        (this.loggedInUser = await AuthService.getLoggedInUser());
    },
    validateForm() {
      this.$v.$touch();

      // Check integration partner is selected or not
      if (this.selectedIntegrationPartner) {
        return !this.$v.$invalid ? true : false;
      } else {
        this.displayRequiredErrorForIntegrationPartner = true;
      }
    },
    clickNextStep() {
      if (
        this.selectedIntegrationPartner &&
        this.selectedIntegrationPartner.name === "keyingress" &&
        !this.loggedInUser.skipIngressIntegrationsPrerequisite
      ) {
        this.displayIngressPrerequisiteModal = true;
      } else if (
        this.selectedIntegrationPartner &&
        this.selectedIntegrationPartner.name === "NIPO" &&
        !this.loggedInUser.userPreferences?.nipoIntegration.username &&
        !this.loggedInUser.userPreferences?.nipoIntegration.password &&
        !this.loggedInUser.userPreferences?.nipoIntegration.region &&
        !this.loggedInUser.userPreferences?.nipoIntegration.domain
      ) {
        this.displayNipoUserAccessModal = true;
      } else {
        this.processNextStepActions();
      }
    },
    processNextStepActions() {
      let isValidationSuccess = this.validateForm();

      if (isValidationSuccess) {
        let liveDatafileMetadataObject = {
          name: this.datafileName,
          integration: this.selectedIntegrationPartner.id,
          integrationName: this.selectedIntegrationPartner.name,
          createdVersion: this.$store.state.appVersion,
          numberOfQuestionAdded: this.totalQuestionAdded
        };

        // set items to local storage
        localStorage.setItem(
          "liveDatafileMetadata",
          JSON.stringify(liveDatafileMetadataObject)
        );

        // redirect to define datafile
        this.$router.push("/define-datafile");
      }
    },
    selectIntegrationPartner() {
      this.displayRequiredErrorForIntegrationPartner = this
        .selectedIntegrationPartner
        ? false
        : true;
    },
    clickRequestNewIntegration() {
      let sub =
        "Request New Integration Partner for " + this.loggedInUser.company.name;
      window.location.assign("mailto:business@redem.io?Subject=" + sub);
    },
    clickOnResetProcess() {
      // reset all local variables
      this.datafileName = null;
      this.selectedIntegrationPartner = null;

      // clean local storage
      window.localStorage.removeItem("pages");
      window.localStorage.removeItem("liveDatafileMetadata");
      window.localStorage.removeItem("enableTotalSurveyDuration");
      window.localStorage.removeItem("maximumCreditAmountForRespondent");
      window.localStorage.removeItem("enebaleCleaningThreshold");
      window.localStorage.removeItem("cleaningSettings");
      window.localStorage.removeItem("numberOfPagesCreated");
      window.localStorage.removeItem("initialSelectionofLanguagesToCheck");
      window.localStorage.removeItem("isEnableWrongTopicCheck");
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="$t('liveImportStep1.pageTitle')" :items="items" />

    <!-- Page Loading -->
    <div v-if="isPageLoading" class="row justify-content-center">
      <div class="col-6 text-center p-5">
        <b-spinner
          style="width: 5rem; height: 5rem"
          class="m-2"
          variant="mid"
          role="status"
        ></b-spinner>
      </div>
    </div>

    <!-- Page Content -->
    <div v-else>
      <!-- Contract RUNNING -->
      <div
        class="row"
        v-if="isAllowedForDataImport"
      >
        <div class="col-lg-12 mt-5">
          <div class="row justify-content-center">
            <!-- Do not Delete -->
            <div class="col-md-2 col-lg-2"></div>

            <!-- Form Component -->
            <div class="col-md-6 col-lg-6">
              <div class="card">
                <div class="card-body">
                  <!-- Form Title -->
                  <div class="row mb-4">
                    <div class="col-8">
                      <h5>{{ $t("liveImportStep1.form.title") }}</h5>
                    </div>
                    <div class="col-4 text-right" v-if="isStep1Completed">
                      <b-button
                        variant="warning"
                        @click="clickOnResetProcess()"
                      >
                        {{ $t("liveImportStep1.form.buttons.reset") }}
                        <i class="mdi mdi-autorenew"></i>
                      </b-button>
                    </div>
                  </div>
                  <!-- Form -->
                  <div class="row">
                    <!-- Project Name -->
                    <div class="col-12">
                      <div class="form-group mb-5">
                        <label for="datafileName">
                          {{ $t("liveImportStep1.form.datafileName.label") }}
                          <span class="text-danger">*</span>
                          <span>
                            <p
                              v-if="datafileName"
                              class="badge position-absolute ml-2"
                              :class="{
                                'bg-light': datafileName.length !== 100,
                                'bg-danger': datafileName.length === 100
                              }"
                            >
                              {{ datafileName.length }} / 100
                            </p>
                          </span>
                        </label>
                        <input
                          v-model.trim="datafileName"
                          id="datafileName"
                          type="text"
                          class="form-control"
                          name="datafileName"
                          :placeholder="
                            $t('liveImportStep1.form.datafileName.placeholder')
                          "
                          :class="{ 'is-invalid': $v.datafileName.$error }"
                        />
                        <div
                          v-if="$v.datafileName.$error"
                          class="invalid-feedback"
                        >
                          <span
                            v-if="
                              $v.datafileName.$error &&
                              !$v.datafileName.required
                            "
                            >{{
                              $t(
                                "liveImportStep1.form.datafileName.requiredErrorMsg"
                              )
                            }}</span
                          >
                          <span
                            v-if="
                              $v.datafileName.$error &&
                              !$v.datafileName.maxLength
                            "
                            >{{
                              $t(
                                "liveImportStep1.form.datafileName.maxLengthErrorMsg"
                              )
                            }}</span
                          >
                        </div>
                      </div>
                    </div>

                    <!-- Integration Partner -->
                    <div class="col-12">
                      <div class="form-group mb-3">
                        <div class="row mb-2">
                          <div class="col-4">
                            <label for="integrationPartner">
                              {{
                                $t(
                                  "liveImportStep1.form.IntegrationPartner.label"
                                )
                              }}
                              <span class="text-danger">*</span>
                            </label>
                          </div>
                          <div class="col-8 text-right">
                            <b-button
                              variant="primary"
                              @click="clickRequestNewIntegration()"
                              size="sm"
                            >
                              <i class="bx bx-plus bx-tada mr-2"></i>
                              {{
                                $t(
                                  "liveImportStep1.form.IntegrationPartner.button.requestNewIntegration"
                                )
                              }}
                            </b-button>
                          </div>
                        </div>

                        <multiselect
                          v-model="selectedIntegrationPartner"
                          :options="allIntegrationPartners"
                          :placeholder="
                            $t(
                              'liveImportStep1.form.IntegrationPartner.placeholder'
                            )
                          "
                          track-by="name"
                          :searchable="false"
                          label="name"
                          @input="selectIntegrationPartner"
                        >
                          <template slot="singleLabel" slot-scope="{ option }">
                            {{ option.name }}
                          </template>
                        </multiselect>
                        <small
                          class="text-danger mt-1"
                          v-if="displayRequiredErrorForIntegrationPartner"
                        >
                          {{
                            $t(
                              "liveImportStep1.form.IntegrationPartner.requiredErrorMsg"
                            )
                          }}
                        </small>
                      </div>
                    </div>
                  </div>

                  <!-- Forsta Theme File Downalod -->
                  <div class="row" v-if="selectedIntegrationPartner && selectedIntegrationPartner.name === 'Forsta Plus'">
                    <div class="col-md-12">
                      <b-alert show variant="dark">
                        <div class="row">
                          <div class="col-8">
                            {{$t("liveImportStep1.forsta.text")}}
                          </div>
                          <div class="col-4 text-right">
                            <b-button
                              variant="dark"
                              class="btn-sm"
                            >
                              <a class="text-white" href="https://redem-resources.s3.eu-central-1.amazonaws.com/survey-templates/ReDem+Quality+Check+Template.zip"> <i class='bx bxs-download mr-1'></i> {{$t("liveImportStep1.forsta.download")}}</a>
                            </b-button>
                          </div>
                        </div>
                      </b-alert>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Do not Delete -->
            <div class="col-md-2 col-lg-2"></div>
          </div>
        </div>

        <!-- Next Button -->
        <div class="fixed-bottom justify-content-end static-buttons">
          <div class="card-body col-md-12 text-right">
            <b-button variant="primary" @click="clickNextStep()" class="mr-2">
              {{ $t("liveImportStep1.form.buttons.next") }}
              <i class="mdi mdi-chevron-right"></i>
            </b-button>
          </div>
        </div>
      </div>

      <!-- Contract PAUSE -->
      <div
        class="row justify-content-center"
        v-else
      >
        <div class="col-md-6 text-center p-5">
          <img src="@/assets/images/information.png" style="height: 60px"/>
          <h5 class="mb-3 mt-4">{{ $t("liveImportStep1.contractPause.text1") }}</h5>
          {{ $t("liveImportStep1.contractPause.text2") }}
          <br />
          <b-button variant="success" @click="clickOnContact()" class="mt-3">
            <i class="bx bx-envelope font-size-18 mr-2"></i
            >{{ $t("liveImportStep1.contractPause.button") }}
          </b-button>
        </div>
      </div>

    </div>

    <!-- Ingress Prerequisite Modal -->
    <IngressPrerequisiteModal
      v-if="displayIngressPrerequisiteModal === true"
      :visible="displayIngressPrerequisiteModal"
      :loggedInUser="this.loggedInUser"
      @close="processNextStepActions"
    />

    <!-- Nipo User Access Modal -->
    <NipoUserAccessModal
      v-if="displayNipoUserAccessModal === true"
      :visible="displayNipoUserAccessModal"
      @close="closeNipoModal"
      :firstTime="true"
    />
  </Layout>
</template>

<style scoped>
.pointer {
  cursor: pointer;
}

.static-buttons {
  z-index: 1;
  margin: 0;
  background-color: white;
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.06), 0 1px 0 0 rgba(0, 0, 0, 0.02);
}
</style>
