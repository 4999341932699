var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Layout",
    [
      _c("PageHeader", {
        attrs: { title: _vm.$t("liveImportStep1.pageTitle"), items: _vm.items },
      }),
      _vm.isPageLoading
        ? _c("div", { staticClass: "row justify-content-center" }, [
            _c(
              "div",
              { staticClass: "col-6 text-center p-5" },
              [
                _c("b-spinner", {
                  staticClass: "m-2",
                  staticStyle: { width: "5rem", height: "5rem" },
                  attrs: { variant: "mid", role: "status" },
                }),
              ],
              1
            ),
          ])
        : _c("div", [
            _vm.isAllowedForDataImport
              ? _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-lg-12 mt-5" }, [
                    _c("div", { staticClass: "row justify-content-center" }, [
                      _c("div", { staticClass: "col-md-2 col-lg-2" }),
                      _c("div", { staticClass: "col-md-6 col-lg-6" }, [
                        _c("div", { staticClass: "card" }, [
                          _c("div", { staticClass: "card-body" }, [
                            _c("div", { staticClass: "row mb-4" }, [
                              _c("div", { staticClass: "col-8" }, [
                                _c("h5", [
                                  _vm._v(
                                    _vm._s(_vm.$t("liveImportStep1.form.title"))
                                  ),
                                ]),
                              ]),
                              _vm.isStep1Completed
                                ? _c(
                                    "div",
                                    { staticClass: "col-4 text-right" },
                                    [
                                      _c(
                                        "b-button",
                                        {
                                          attrs: { variant: "warning" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.clickOnResetProcess()
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "liveImportStep1.form.buttons.reset"
                                                )
                                              ) +
                                              " "
                                          ),
                                          _c("i", {
                                            staticClass: "mdi mdi-autorenew",
                                          }),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]),
                            _c("div", { staticClass: "row" }, [
                              _c("div", { staticClass: "col-12" }, [
                                _c("div", { staticClass: "form-group mb-5" }, [
                                  _c(
                                    "label",
                                    { attrs: { for: "datafileName" } },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "liveImportStep1.form.datafileName.label"
                                            )
                                          ) +
                                          " "
                                      ),
                                      _c(
                                        "span",
                                        { staticClass: "text-danger" },
                                        [_vm._v("*")]
                                      ),
                                      _c("span", [
                                        _vm.datafileName
                                          ? _c(
                                              "p",
                                              {
                                                staticClass:
                                                  "badge position-absolute ml-2",
                                                class: {
                                                  "bg-light":
                                                    _vm.datafileName.length !==
                                                    100,
                                                  "bg-danger":
                                                    _vm.datafileName.length ===
                                                    100,
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.datafileName.length
                                                    ) +
                                                    " / 100 "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]),
                                    ]
                                  ),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model.trim",
                                        value: _vm.datafileName,
                                        expression: "datafileName",
                                        modifiers: { trim: true },
                                      },
                                    ],
                                    staticClass: "form-control",
                                    class: {
                                      "is-invalid": _vm.$v.datafileName.$error,
                                    },
                                    attrs: {
                                      id: "datafileName",
                                      type: "text",
                                      name: "datafileName",
                                      placeholder: _vm.$t(
                                        "liveImportStep1.form.datafileName.placeholder"
                                      ),
                                    },
                                    domProps: { value: _vm.datafileName },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.datafileName =
                                          $event.target.value.trim()
                                      },
                                      blur: function ($event) {
                                        return _vm.$forceUpdate()
                                      },
                                    },
                                  }),
                                  _vm.$v.datafileName.$error
                                    ? _c(
                                        "div",
                                        { staticClass: "invalid-feedback" },
                                        [
                                          _vm.$v.datafileName.$error &&
                                          !_vm.$v.datafileName.required
                                            ? _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "liveImportStep1.form.datafileName.requiredErrorMsg"
                                                    )
                                                  )
                                                ),
                                              ])
                                            : _vm._e(),
                                          _vm.$v.datafileName.$error &&
                                          !_vm.$v.datafileName.maxLength
                                            ? _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "liveImportStep1.form.datafileName.maxLengthErrorMsg"
                                                    )
                                                  )
                                                ),
                                              ])
                                            : _vm._e(),
                                        ]
                                      )
                                    : _vm._e(),
                                ]),
                              ]),
                              _c("div", { staticClass: "col-12" }, [
                                _c(
                                  "div",
                                  { staticClass: "form-group mb-3" },
                                  [
                                    _c("div", { staticClass: "row mb-2" }, [
                                      _c("div", { staticClass: "col-4" }, [
                                        _c(
                                          "label",
                                          {
                                            attrs: {
                                              for: "integrationPartner",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "liveImportStep1.form.IntegrationPartner.label"
                                                  )
                                                ) +
                                                " "
                                            ),
                                            _c(
                                              "span",
                                              { staticClass: "text-danger" },
                                              [_vm._v("*")]
                                            ),
                                          ]
                                        ),
                                      ]),
                                      _c(
                                        "div",
                                        { staticClass: "col-8 text-right" },
                                        [
                                          _c(
                                            "b-button",
                                            {
                                              attrs: {
                                                variant: "primary",
                                                size: "sm",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.clickRequestNewIntegration()
                                                },
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "bx bx-plus bx-tada mr-2",
                                              }),
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "liveImportStep1.form.IntegrationPartner.button.requestNewIntegration"
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]),
                                    _c("multiselect", {
                                      attrs: {
                                        options: _vm.allIntegrationPartners,
                                        placeholder: _vm.$t(
                                          "liveImportStep1.form.IntegrationPartner.placeholder"
                                        ),
                                        "track-by": "name",
                                        searchable: false,
                                        label: "name",
                                      },
                                      on: {
                                        input: _vm.selectIntegrationPartner,
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "singleLabel",
                                            fn: function ({ option }) {
                                              return [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(option.name) +
                                                    " "
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        1948735983
                                      ),
                                      model: {
                                        value: _vm.selectedIntegrationPartner,
                                        callback: function ($$v) {
                                          _vm.selectedIntegrationPartner = $$v
                                        },
                                        expression:
                                          "selectedIntegrationPartner",
                                      },
                                    }),
                                    _vm.displayRequiredErrorForIntegrationPartner
                                      ? _c(
                                          "small",
                                          { staticClass: "text-danger mt-1" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "liveImportStep1.form.IntegrationPartner.requiredErrorMsg"
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ]),
                            ]),
                            _vm.selectedIntegrationPartner &&
                            _vm.selectedIntegrationPartner.name ===
                              "Forsta Plus"
                              ? _c("div", { staticClass: "row" }, [
                                  _c(
                                    "div",
                                    { staticClass: "col-md-12" },
                                    [
                                      _c(
                                        "b-alert",
                                        {
                                          attrs: { show: "", variant: "dark" },
                                        },
                                        [
                                          _c("div", { staticClass: "row" }, [
                                            _c(
                                              "div",
                                              { staticClass: "col-8" },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "liveImportStep1.forsta.text"
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "col-4 text-right",
                                              },
                                              [
                                                _c(
                                                  "b-button",
                                                  {
                                                    staticClass: "btn-sm",
                                                    attrs: { variant: "dark" },
                                                  },
                                                  [
                                                    _c(
                                                      "a",
                                                      {
                                                        staticClass:
                                                          "text-white",
                                                        attrs: {
                                                          href: "https://redem-resources.s3.eu-central-1.amazonaws.com/survey-templates/ReDem+Quality+Check+Template.zip",
                                                        },
                                                      },
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "bx bxs-download mr-1",
                                                        }),
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "liveImportStep1.forsta.download"
                                                              )
                                                            )
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ])
                              : _vm._e(),
                          ]),
                        ]),
                      ]),
                      _c("div", { staticClass: "col-md-2 col-lg-2" }),
                    ]),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass:
                        "fixed-bottom justify-content-end static-buttons",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "card-body col-md-12 text-right" },
                        [
                          _c(
                            "b-button",
                            {
                              staticClass: "mr-2",
                              attrs: { variant: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.clickNextStep()
                                },
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("liveImportStep1.form.buttons.next")
                                  ) +
                                  " "
                              ),
                              _c("i", { staticClass: "mdi mdi-chevron-right" }),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ])
              : _c("div", { staticClass: "row justify-content-center" }, [
                  _c(
                    "div",
                    { staticClass: "col-md-6 text-center p-5" },
                    [
                      _c("img", {
                        staticStyle: { height: "60px" },
                        attrs: {
                          src: require("@/assets/images/information.png"),
                        },
                      }),
                      _c("h5", { staticClass: "mb-3 mt-4" }, [
                        _vm._v(
                          _vm._s(_vm.$t("liveImportStep1.contractPause.text1"))
                        ),
                      ]),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("liveImportStep1.contractPause.text2")
                          ) +
                          " "
                      ),
                      _c("br"),
                      _c(
                        "b-button",
                        {
                          staticClass: "mt-3",
                          attrs: { variant: "success" },
                          on: {
                            click: function ($event) {
                              return _vm.clickOnContact()
                            },
                          },
                        },
                        [
                          _c("i", {
                            staticClass: "bx bx-envelope font-size-18 mr-2",
                          }),
                          _vm._v(
                            _vm._s(
                              _vm.$t("liveImportStep1.contractPause.button")
                            ) + " "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
          ]),
      _vm.displayIngressPrerequisiteModal === true
        ? _c("IngressPrerequisiteModal", {
            attrs: {
              visible: _vm.displayIngressPrerequisiteModal,
              loggedInUser: this.loggedInUser,
            },
            on: { close: _vm.processNextStepActions },
          })
        : _vm._e(),
      _vm.displayNipoUserAccessModal === true
        ? _c("NipoUserAccessModal", {
            attrs: { visible: _vm.displayNipoUserAccessModal, firstTime: true },
            on: { close: _vm.closeNipoModal },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }